import React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/core"

import { theme } from "../styles/theme"

const CustomNavTitle = ({ title, destination, location }) => {
  const { state } = location

  let backOverride = null;
  if (state) {
    backOverride = state.backOverride;
  }

  return (
    <div>
      <div css={styles.banner}>
        <div css={[theme.components.row, styles.bannerRow]}>
          <p css={styles.bannerText}>For Patients 18 Years and Over.</p>
        </div>
      </div>
      <div css={styles.wrap}>
        <div css={[theme.components.row, styles.nav]}>
          <Link
            css={styles.button}
            to={backOverride ? backOverride : destination}
            title="Go back"
            state={location.state}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10.214"
              height="17.14"
              viewBox="0 0 10.214 17.14"
            >
              <path
                d="M70.417,43.491c-.027-.027-.058-.046-.086-.071l-6.856-6.856a1.636,1.636,0,0,0-2.314,0h0a1.636,1.636,0,0,0,0,2.314l5.777,5.777-5.777,5.777a1.636,1.636,0,0,0,0,2.314h0a1.636,1.636,0,0,0,2.314,0l6.856-6.856c.028-.025.059-.044.086-.071a1.657,1.657,0,0,0,0-2.33Z"
                transform="translate(70.896 53.226) rotate(180)"
                fill={theme.colours.red}
              />
            </svg>
          </Link>
          <h1 css={[theme.components.title, theme.components.titleLarge]}>
            {title}
          </h1>
        </div>
      </div>
    </div>
  )
}

const styles = {
  wrap: css({
    backgroundColor: theme.colours.lightGrey,
  }),
  banner: css({
    backgroundColor: theme.colours.darkPink,
  }),
  bannerRow: css({
    paddingTop: theme.gapHalf,
    paddingBottom: theme.gapHalf,
  }),
  bannerText: css({
    color: theme.colours.white,
    fontWeight: 'bold',
  }),
  nav: css({
    display: "flex",
    alignItems: "center",
  }),
  button: css({
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    width: "40px",
    height: "40px",
    marginRight: theme.gapHalf,
    borderRadius: "50%",
    "&:hover, &:focus": {
      backgroundColor: "#e0e0e0",
    },
  }),
}

export default CustomNavTitle
