import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import { theme } from "../styles/theme"

import logo from "../images/logos/logo-blood-assist.svg"
import logocolour from "../images/logos/logo-blood-assist-colour.svg"
import nhslogo from "../images/logos/logo-nhs-blood-transplant.svg"

const Header = ({ homeLink = true, isTermsPage = false }) => (
  <header
    css={{
      background: isTermsPage ? theme.colours.white : theme.colours.red,
    }}
  >
    <div
      css={[
        theme.components.row,
        {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        },
      ]}
    >
      {homeLink && (
        <Link
          to="/"
          css={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="35"
            height="31.639"
            viewBox="0 0 35 31.639"
          >
            <path
              d="M242.692 148.343l-1.771 1.378a1.234 1.234 0 01-1.861-.043 1.3 1.3 0 01.432-1.869l13.568-10.5c.8-.616 1.588-1.236 2.387-1.848a1.216 1.216 0 011.766.012q7.977 6.173 15.957 12.341a1.359 1.359 0 01.611 1.452.966.966 0 01-.9.81 1.9 1.9 0 01-1.053-.3c-.623-.407-1.189-.9-1.844-1.414-.015.219-.035.368-.035.517V165.331a1.267 1.267 0 01-1.409 1.389h-24.364c-1.017 0-1.487-.478-1.487-1.5v-16.211zm24.881 15.963c.01-.1.022-.161.022-.223q0-8.634-.01-17.269a.734.734 0 00-.265-.5q-5.333-4.146-10.679-8.275a.438.438 0 00-.652.005q-5.316 4.13-10.653 8.231a.707.707 0 00-.3.644q.011 8.466.005 16.931v.448h6.214v-.545-9.841a1.3 1.3 0 011.446-1.45h7.259a1.259 1.259 0 011.418 1.413q.006 4.993 0 9.985v.441zM259 154.89h-5.352v9.4H259z"
              transform="translate(-238.818 -135.09)"
              fill="#fff"
            />
          </svg>
        </Link>
      )}
      <img
        src={isTermsPage ? logocolour : logo}
        alt="NHS Blood Assist"
        css={{
          maxWidth: 151,
          marginLeft: isTermsPage ? 0 : "auto",
        }}
      />

      {isTermsPage && (
        <img
          src={nhslogo}
          alt="NHS Blood and Transplant"
          css={{
            maxWidth: 184,
          }}
        />
      )}
    </div>
  </header>
)

Header.propTypes = {
  homeLink: PropTypes.bool,
}

Header.defaultProps = {
  homeLink: true,
}

export default Header
