import React from "react"

import { css } from "@emotion/core"

import { theme } from "../styles/theme"

const DefaultLayoutSidebar = ({ children }) => {
  return (
    <article css={[theme.components.row, styles.withSidebar]}>
      <div>{children}</div>
    </article>
  )
}

const styles = {
  withSidebar: css({
    overflow: "hidden",
    "& > *": {
      display: "flex",
      flexWrap: "wrap",
      margin: "calc(6rem / 2 * -1)",
      "& > *": {
        margin: "calc(6rem / 2)",
        flexBasis: "540px",
        flexGrow: 1,
      },
      "& > :first-child": {
        flexBasis: 0,
        flexGrow: 999,
        minWidth: "calc(50% - 1rem)",
        marginBottom: theme.gap,
      },
    },
  }),
}

export default DefaultLayoutSidebar
