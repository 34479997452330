import React, { useContext, useEffect } from "react"
import { navigate } from "gatsby"
import { TermsContext } from "../context/provider"
import PropTypes from "prop-types"

import firebase from "gatsby-plugin-firebase"

import Header from "./header"
import Footer from "./footer"
import "../styles/layout.css"

const Layout = ({ homeLink = true, isTermsPage = false, children }) => {
  const terms = useContext(TermsContext)

  const checkActiveTime = () => {
    const MINUTES_UNITL_INACTIVE = 5 // in mins
    const STORE_KEY = "lastAction" // for localstorage

    const setTimestamp = () => {
      localStorage.setItem(STORE_KEY, Date.now())
    }
    const lastTime = localStorage.getItem(STORE_KEY)

    setTimestamp()
    if (lastTime) {
      const now = Date.now()
      const lastTimeInt = parseInt(lastTime, 10)
      const timeleft = lastTimeInt + MINUTES_UNITL_INACTIVE * 60 * 1000
      const difference = timeleft - now
      console.log({timeleft, difference, now});
      if (difference < 0) {
        setTimestamp()
        navigate("/terms", { state: { timeOut: true } })
      }
    }
  }
  useEffect(checkActiveTime, [])

  // Track pageviews
  useEffect(() => {
    if (firebase && typeof window === "object") {
      firebase.analytics().setCurrentScreen(window.location.pathname)
      firebase.analytics().logEvent("page_view", {
        'path' : window.location.pathname
      })
      firebase.analytics().logEvent("screen_view", {
        'screen_name' : window.location.pathname
      })
    }
  }, [])

  if (terms) {
    firebase.analytics()
    const { termsAccepted = null } = terms

    if (!isTermsPage && !termsAccepted) {
      navigate("/terms")
    }
  }

  return (
    <>
      <Header homeLink={homeLink} isTermsPage={isTermsPage} />
      {!terms && (
        <main>
          <div class="max-w-content mx-auto px-gap">
            <h1 class="mt-gap-2 text-black">
              You must accept the Terms of Use before using this website.
            </h1>
            <p class="mt-gap-2">Terms of Use loading...</p>
            <div class="mt-gap">
              <img src="/loading-spinner.svg" alt="loading" />
            </div>
          </div>
        </main>
      )}
      {terms && <main>{children}</main>}
      {!isTermsPage && <Footer />}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  homeLink: PropTypes.bool,
  isTermsPage: PropTypes.bool,
}

export default Layout
