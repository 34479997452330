import React from "react"
import logo from "../images/logos/logo-nhs-blood-transplant.svg"
import { theme } from "../styles/theme"

const Footer = () => (
  <footer
    css={{
      marginTop: theme.gapDouble,
    }}
  >
    <div
      css={[
        theme.components.row,
        {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderTop: `4px solid ${theme.colours.lightGrey}`,
        },
      ]}
    >
      <p>© {new Date().getFullYear()} NHS Blood and Transplant</p>

      <img
        src={logo}
        alt="NHS Blood and Transplant"
        css={{
          maxWidth: 184,
        }}
      />
    </div>
  </footer>
)

export default Footer
