import { css } from "@emotion/core"

const colours = {
  red: "#DA291C",
  lightRed: "#FBE8E5",
  darkPink: '#7C2855',
  blue: "#0072C6",
  yellow: "#FF9D00",
  lightGrey: "#EFEFEF",
  midGrey: "#DFDFDF",
  darkGrey: "#707070",
  black: "#333333",
  white: "#FFFFFF",
}
const borderRadius = "8px"
const gapDouble = "2rem"
const gap = "1rem"
const gapHalf = "0.5rem"
const fzH1 = "1.875rem"
const fzH2 = "1.5rem"
const fzH3 = "1.375rem"

export const theme = {
  colours,
  borderRadius,
  gapDouble,
  gap,
  gapHalf,
  fzH1,
  fzH2,
  fzH3,
  components: {
    row: css({
      maxWidth: "1640px",
      padding: "1.5rem 1rem",
      margin: "0 auto",
    }),
    title: css({
      color: colours.black,
      fontWeight: "bold",
      fontSize: "1.5rem",
    }),
    titleSmall: css({
      fontSize: "1.375rem",
    }),
    titleLarge: css({
      fontSize: "1.875rem",
    }),
    titleRed: css({
      color: colours.red,
    }),
    button: css({
      display: "inline-block",
      padding: `${gap} ${gapDouble}`,
      borderRadius: borderRadius,
      fontWeight: "bold",
      textDecoration: "none",
      textAlign: "center",
      backgroundColor: colours.red,
      color: colours.white,
      "&:hover, &:focus": {
        backgroundColor: "#b91600",
      },
    }),
    buttonGrey: css({
      backgroundColor: colours.lightGrey,
      color: colours.red,
      "&:hover, &:focus": {
        backgroundColor: "#e0e0e0",
      },
    }),
    buttonLink: css({
      padding: "3rem 5rem 3rem 2rem",
      textAlign: "left",
      backgroundRepeat: "no-repeat",
      backgroundImage: `url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15.421 25.88'%3E%3Cpath d='M75.381,47.266c-.041-.041-.087-.069-.13-.107L64.9,36.809a2.471,2.471,0,0,0-3.494,0h0a2.471,2.471,0,0,0,0,3.494l8.722,8.722-8.722,8.723a2.471,2.471,0,0,0,0,3.494h0a2.471,2.471,0,0,0,3.494,0L75.251,50.89c.043-.037.09-.066.13-.107a2.5,2.5,0,0,0,0-3.517Z' transform='translate(-60.682 -36.085)' fill='%23DA291C'/%3E%3C/svg%3E")`,
      backgroundSize: "12.53px 21.03px",
      backgroundPosition: "right 2rem center",
    }),
    bloodGroupIcon: css({
      height: 92,
      width: 92,
      color: colours.black,
      backgroundColor: colours.lightGrey,
      fontSize: fzH1,
      fontWeight: "bold",
      display: "inline-flex",
      justifyContent: "center",
      textAlign: "center",
      alignItems: "center",
      borderRadius: borderRadius,
      marginRight: gap,
    }),
    linkButtonList: css({
      marginTop: gapDouble,
      display: "flex",
      flexWrap: "wrap",
      marginRight: "-1rem",
      "&>*": {
        flexBasis: "calc(25% - 1rem)",
        marginRight: "1rem",
        minWidth: "320px",
        "@media (max-width: 920px)": {
          flexBasis: "calc(50% - 1rem)",
        },
        "@media (max-width: 620px)": {
          flexBasis: "100%",
        },
      },
    }),
    listLinkButton: css({
      height: "100%",
      display: "flex",
      alignItems: "center",
    }),
  },
}
